<template>
   <main id="main" class="site-main">
       <PricingPlan2List />
   </main>
</template>
<script>
import PricingPlan2List from './PricingPlan2/PricingPlan2List'
export default {
  name: 'PricingPlan2',
  components: {
    PricingPlan2List
  }
}
</script>
